import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styles from './index-page-content.module.css'
import ExtLink from '../ext-link/ext-link'
import InstagramPosts from '../instagram-posts/instagram-posts'

const IndexPageContent = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          links {
            label
            link
            linkKey
          }
        }
      }
    }
  `)

  const links = data.site.siteMetadata.links

  return (
    <div className='container'>
      <div className={styles.list}>
        {links.map((link, index) => (
          <ExtLink {...link} key={index} />
        ))}
      </div>

      <InstagramPosts />
    </div>
  )
}

export default IndexPageContent
