import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import IndexPageContent from '../components/index-page-content/index-page-content'

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />

    <IndexPageContent />
  </Layout>
)

export default IndexPage
