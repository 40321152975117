import React, { memo, useEffect, useState } from 'react'
import cx from 'classnames'

import styles from './instagram-posts.module.css'

function useInstagram(numberOfPosts = 0) {
  const [posts, setPosts] = useState([])
  const url =
    numberOfPosts !== 0 && !Number.isNaN(Number(numberOfPosts))
      ? `/.netlify/functions/instagram?n=${numberOfPosts}`
      : '/.netlify/functions/instagram'

  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setPosts(data)
      })
  }, [url])

  return posts
}

const InstagramPosts = memo(function InstagramPosts() {
  const gramz = useInstagram(5)

  return (
    <div className={styles.instaWrapper}>
      <h3>Photos Instgram</h3>
      <div className={styles.instagramPosts}>
        {gramz.length > 0 ? (
          <Instaz gramz={gramz} />
        ) : (
            <>
              <div className={cx(styles.post, styles.loading)}></div>
              <div className={cx(styles.post, styles.loading)}></div>
              <div className={cx(styles.post, styles.loading)}></div>
            </>
          )}
      </div>
    </div>
  )
})

const Instaz = ({ gramz }) => (
  <>
    {gramz.map(gram => (
      <a
        className={styles.post}
        href={gram.url}
        target='_blank'
        rel='nofollow noopener noreferrer'
        key={gram.id}
      >
        <img src={gram.thumbnail} alt={gram.caption} loading='lazy' />
      </a>
    ))}
    <a
      className={cx(styles.post, styles.allPostsLink)}
      href='https://instagram.com/altessecake'
      target='_blank'
      rel='nofollow noopener noreferrer'
    >
      <span>Toutes les photos instagram</span>
    </a>
  </>
)

export default InstagramPosts
