import React from 'react'
import cx from 'classnames'

import Icon from '../icon/icon'
import styles from './ext-link.module.css'

const ExtLink = ({ label, link, linkKey }) => {
  const linkContent = (
    <>
      <span className={cx(styles.linkIcon, styles[linkKey])}>
        <Icon name={linkKey} />
      </span>
      <span className={cx(styles.linkLabel, linkKey)}>{label}</span>
    </>
  )

  return link ? (
    <a
      className={styles.extLink}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
    >
      {linkContent}
    </a>
  ) : (
    <span className={styles.extLink}>{linkContent}</span>
  )
}

export default ExtLink
